<script setup>
import { userAuthState } from "@/@core/auth"
import { can } from "@/@layouts/plugins/casl"

import RCATable from "@/views/incident/rca/RcaTable.vue"
import RCADrawer from "@/views/incident/rca/drawer/RcaDrawer.vue"

import DummyFilterOptions from '@/utils/dummy-data/filter'

definePage({
  meta: {
    name: "incident-rca",
    subject: "incident.incident_rca",
    action: "read",
  },
})

const auth = userAuthState()

const refRCATable = ref(null)

const isRCADrawerVisible = ref(false)
const rcaDrawerData = ref()

const showRca = data => {
  rcaDrawerData.value = data
  isRCADrawerVisible.value = true
}

const showRcaResponse = data => {
  rcaDrawerData.value = data
  isRCADrawerVisible.value = true
}

const filters = ref({})
const { data: filterOptions } = await useApi(createUrl('/incidents/incident-rcas-filters'))
</script>

<template>
  <section>
    <VRow class="match-height mb-6">
      <VCol
        cols="12"
        md="6"
        lg="6"
      >
        <FilterComponent
          v-model:filters="filters"
          :filters-options="filterOptions"
        />
      </VCol>
      <VCol
        cols="12"
        md="6"
        lg="6"
      >
        <VCard>
          <VCardItem> Coming Soon </VCardItem>
        </VCard>
      </VCol>
    </VRow>

    <RCATable
      ref="refRCATable"
      :filters="filters"
      @show-rca="showRca"
      @show-rca-response="showRcaResponse"
    />

    <RCADrawer
      v-model:isDrawerOpen="isRCADrawerVisible"
      :feedback-data="rcaDrawerData"
    />
  </section>
</template>
