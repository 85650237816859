import App from '@/App.vue'
import { userAuthState } from '@core/auth'
import { registerPlugins } from '@core/utils/plugins'

import * as Sentry from "@sentry/vue"
import { createPinia } from 'pinia'
import piniaPluginPersistedState from "pinia-plugin-persistedstate"

// import custom service worker
// import { registerSW } from 'virtual:pwa-register'
import { createApp } from 'vue'

// import router
import { router } from '@/plugins/2.router/index'


// Styles
import '@core/scss/template/index.scss'
import '@styles/styles.scss'

// Create vue app
const app = createApp(App)

const store = createPinia()

store.use(piniaPluginPersistedState)

app.use(store)

const auth = userAuthState()

if (localStorage.getItem('accessToken')){
  auth.fetchUser()
}

registerPlugins(app)

// sentry issue tracker
// check it is production or not
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://1f0cbc6dd6b61001d59623354843b607@o795642.ingest.us.sentry.io/4507213793984512",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    denyUrls: ['localhost'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  Sentry.setUser({
    id: auth?.user?.id, // User ID
    employee_code: auth?.user?.employee_code, // Employee code
    // Add other user properties if needed
  })
}

// registerSW({
//   immediate: true,
// })

// Provide auth and store to all components
app.config.globalProperties.$auth = auth
app.config.globalProperties.$store = store

// structureClone support for older browsers window
if (typeof structuredClone !== 'function') {
  console.log('structuredClone not supported')
  window.structuredClone = function (obj) {
    return JSON.parse(JSON.stringify(obj))
  }
}else{
  console.log('structuredClone supported')
}

// Mount vue app
app.mount('#app')
