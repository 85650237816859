<script setup>
import { paginationMeta } from '@/@core/utils/paginationMeta'
import { cookieRef } from '@/@layouts/stores/config'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import locationTypeService from '@/services/locationType.service'
import LocationTypeDrawer from '@/views/masters/locationType/drawer/LocationTypeDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

const searchQuery = ref('')
const isConfirmDialogVisible = ref(false)

definePage({
  meta: {
    name: 'master-location-type',
    subject: 'master.location_type',
    action: 'read',
  },  
})

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()

const errors = ref({
  name: [],
  code: [],
  status: [],
})

const onError = error => {
  if (error.data){
    
    errors.value = error.data.errors
  }
}

const updateOptions = options => {
  page.value = options.page
  orderBy.value = options.sortBy[0]?.key
  sortBy.value = options.sortBy[0]?.order.toUpperCase()
}

// Headers
const headers = [
  {
    title: 'Name',
    key: 'name',
  },
 
  {
    title: 'Order',
    key: 'order',
  },
   
  {
    title: 'Unit',
    key: 'unit.name',
  },
  
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: locationTypesData,
  execute: fetchLocationTypes,
} =  await useApi(createUrl('/masters/location-types', {
  query: {
    paged: 1,
    q: searchQuery,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
    search: {
      unit_id: cookieRef('selectedUnit'),
    },
  },
}))

const locationTypes = computed(() => locationTypesData.value?.data??[])
const totalCategories = computed(() => locationTypesData.value?.meta?.total??0)

const updateLocationType = data => {
  if (data.id){
    locationTypeService.updateLocationType(data.id, data, ()=>
    {
      fetchLocationTypes()
      isLocationTypeDrawerVisible.value = false
      resetErrors()
    }, onError)
   
  }
}

const saveLocationType = data => {
  locationTypeService.addLocationType(data, ()=>
  {
    fetchLocationTypes()
    isLocationTypeDrawerVisible.value = false
    resetErrors()

  }, onError)
}

const resetErrors = () => {
  errors.value = {
    name: [],
    code: [],
    status: [],
  }
}

const isLocationTypeDrawerVisible = ref(false)
const locationTypeDrawerData = ref()
const locationTypeDeleteData = ref()
const locationTypeDrawerType = ref('Add')

const showLocationType = (locationTypeData, type) => {
  locationTypeDrawerData.value = locationTypeData
  locationTypeDrawerType.value = type  
  isLocationTypeDrawerVisible.value = true
}

const deleteLocationType = async (confirm, data) => {
  if(data?.id && confirm ){
    await locationTypeService.deleteLocationType(data.id, () => {
      fetchLocationTypes()
      isConfirmDialogVisible.value = false
    }, onError)
  }
}

const showConfirmDialog = locationTypeData => {
  locationTypeDeleteData.value = locationTypeData
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value){
    locationTypeDeleteData.value = {}
  }
})
</script>

<template>
  <section>
    <VCard>
      <VCardText class="d-flex flex-wrap py-4 gap-4">
        <div class="me-3 d-flex gap-3">
          <AppSelect
            :model-value="itemsPerPage"
            :items="[
              { value: 10, title: '10' },
              { value: 25, title: '25' },
              { value: 50, title: '50' },
              { value: 100, title: '100' },
              { value: -1, title: 'All' },
            ]"
            style="inline-size: 6.25rem;"
            @update:model-value="itemsPerPage = parseInt($event, 10)"
          />
        </div>
        <VSpacer />

        <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div style="inline-size: 10rem;">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search"
              density="compact"
            />
          </div>

          <!-- 👉 Export button -->
          <VBtn
            variant="tonal"
            color="secondary"
            prepend-icon="tabler-screen-share"
          >
            Export
          </VBtn>

       
          <VBtn
            v-if="$can('create', 'master.location_type')"
            prepend-icon="tabler-plus"
            @click="showLocationType({},'Add')"
          >
            Add New Location Type
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="locationTypes"
        :items-length="totalCategories"
        :headers="headers"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.name="{ item }">
          <div
            class="d-flex align-center cursor-pointer"
            :locationTypeData="item"
            @click="showLocationType(item,'View')"
          >
            <VAvatar
              size="34"
              :variant="!item.avatar ? 'tonal' : undefined"
              class="me-3"
            >
              <VImg
                v-if="item.avatar"
                :src="item.avatar"
              />
              <span v-else>{{ avatarText(item.name) }}</span>
            </VAvatar>
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.name }}
              </h6>
            </div>
          </div>
        </template>
        <template #item.code="{ item }">
          <span class="text-capitalize font-weight-medium">{{ item.code }}</span>
        </template>
        <!-- Actions -->
        <template #item.actions="{ item }">
          <IconBtn 
            v-if="$can('delete', 'master.location_type')"
            @click="showConfirmDialog(item)"
          >
            <VIcon icon="tabler-trash" />
          </IconBtn>

          <IconBtn 
            v-if="$can('update', 'master.location_type')"
            @click="showLocationType(item,'Edit')"
          >
            <VIcon icon="tabler-edit" />
          </IconBtn>
        </template>

        <!-- pagination -->
        <template #bottom>
          <VDivider />
          <div class="d-flex align-center justify-sm-space-between justify-center flex-wrap gap-3 pa-5 pt-3">
            <p class="text-sm text-disabled mb-0">
              {{ paginationMeta({ page, itemsPerPage }, totalCategories) }}
            </p>

            <VPagination
              v-model="page"
              :length="Math.ceil(totalCategories / itemsPerPage)"
              :total-visible="$vuetify.display.xs ? 1 : Math.ceil(totalCategories / itemsPerPage)"
            >
              <template #prev="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Previous
                </VBtn>
              </template>

              <template #next="slotProps">
                <VBtn
                  variant="tonal"
                  color="default"
                  v-bind="slotProps"
                  :icon="false"
                >
                  Next
                </VBtn>
              </template>
            </VPagination>
          </div>
        </template>
      </VDataTableServer>
      <!-- SECTION -->
    </VCard>  
    <LocationTypeDrawer
      v-model:isDrawerOpen="isLocationTypeDrawerVisible"
      :location-type-data="locationTypeDrawerData"
      :type="locationTypeDrawerType ?? 'Add'"
      :errors="errors"
      @update:type="locationTypeDrawerType = $event"
      @update:location-type-data="updateLocationType"
      @save-location-type="saveLocationType"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      confirm-msg="LocationType Deleted."
      confirm-title="Deleted!"
      :data="locationTypeDeleteData"
      @confirm="deleteLocationType"
    />
  </section>
</template>
