<script setup>
import { avatarText } from '@/@core/utils/formatters'
import { cookieRef } from '@/@layouts/stores/config'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import ShowUserDrawer from '@/views/apps/user/list/ShowUserDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'

definePage({
  meta: {
    name: 'escalation',
    subject: 'configuration.escalation',
    action: 'read',
  },  
})

const snackbar = useToast()
const unitId = ref(cookieRef('selectedUnit'))

// 👉 Store
const searchQuery = ref('')
const selectedDepartments = ref()
const selectedCategories = ref()
const selectedUnits = ref()
const selectedStatus = ref()
const selectedEscalationLevel = ref()

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()


const { isFetching: escalationLevelLoading, data: escalationLevels } =await useApi(createUrl('/configurations/escalation-level'))

const updateOptions = options => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

// Headers
const headers = [
  { title: '', key: 'data-table-expand' },
  {
    title: 'Department',
    key: 'name',
    sortable: false,
  },

  // {
  //   title: 'Category',
  //   key: 'category.name',
  //   sortable: false,
  // },
  {
    title: 'Levels',
    key: 'levels',
    sortable: false,
  },

  // {
  //   title: 'Categories',
  //   key: 'categories',
  //   sortable: false,
  // },
  // {
  //   title: 'Escalation Type',
  //   key: 'escalations.escalation_type',
  //   sortable: false,
  // },
  // {
  //   title: 'Escalation Level',
  //   key: 'escalation_level.level',
  //   sortable: false,
  // },
  // {
  //   title: 'Users',
  //   key: 'can_have_escalation',
  //   sortable: false,
  // },
  // {
  //   title: 'Actions',
  //   key: 'actions',
  //   sortable: false,
  // },
]

const {
  data: escalationData,
  execute: fetchEscalations,
} =   useApi(createUrl('/configurations/escalations', {
  query: {
    paged: 1,
    q: searchQuery,
    search: {
      department_id: selectedDepartments,
      category_id: selectedCategories,
      units: selectedUnits,
      status: selectedStatus,
      escalation_level_id: selectedEscalationLevel,
      
    },
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
  },
}))

const escalations = computed(() => escalationData.value?.data)
const totalEscalations = computed(() => escalationData.value?.meta?.total)

// 👉 search filters
const { data: departments } =await useApi(createUrl('/masters/departments'))

const { data: categories } =await useApi(createUrl('/masters/categories', {
  query: {
    // unit_id: selectedUnit,
    parent_id: 'null',
  },
}))

const { data: roles } =await useApi(createUrl('/masters/roles'))
const { data: units } = await useApi(createUrl('/masters/units'))

const status = [
  {
    title: 'Active',
    value: 1,
  },
  {
    title: 'Inactive',
    value: 0,
  },
]

const resolveUserRoleVariant = roles => {

  const roleNames = roles.map(role => role.name)

  if (roleNames.includes('admin'))
    return { color: 'primary', icon: 'tabler-shield-check' }
  if (roleNames.includes('user'))
    return { color: 'secondary', icon: 'tabler-user' }
  if (roleNames.includes('manager'))
    return { color: 'warning', icon: 'tabler-user' }
  else
    return { color: 'secondary', icon: 'tabler-user' }
}

const resolveUserStatusVariant = stat => {
  const statLowerCase = stat.toLowerCase()
  if (statLowerCase === 'pending')
    return 'warning'
  if (statLowerCase === 'active')
    return 'success'
  if (statLowerCase === 'inactive')
    return 'secondary'
  
  return 'primary'
}

const isShowUserDrawerVisible = ref(false)
const showUserDrawerData = ref()

const showUser = userData => {
  showUserDrawerData.value = userData
  isShowUserDrawerVisible.value = true
}

const isConfirmDialogVisible = ref(false)
const confirmDialogData = ref()

const showConfirmDialog = data => {
  confirmDialogData.value = data
  isConfirmDialogVisible.value = true
}

watch(isConfirmDialogVisible, () => {
  if (!isConfirmDialogVisible.value) confirmDialogData.value = null
})

const deleteEscalation = async (status, { departmentId, categoryId }) => {

  if (status) {
    let api = categoryId ? `/configurations/escalations/${ unitId.value }/${ departmentId }/${ categoryId }` 
      : `/configurations/escalations/${ unitId.value }/${ departmentId }`
    await $api(api, 
      { method: 'DELETE' },
    ).then(() => {
      snackbar.success(`Escalation deleted successfully`, 'success')
      fetchEscalations()
    })
  }

}

// Function to group escalations by category
const groupByCategory = data => {
  const group = data.reduce((acc, value) => {
    // Use category id as key
    const key = value.category?.id

    // If the category key doesn't exist in the accumulator, create it
    if (!acc[key]) {
      acc[key] = {
        category_id: value.category?.id ?? null,
        category_name: value.category?.name ?? '',
        escalations: [],
      }
    }

    // Push the escalation to the appropriate category group
    acc[key].escalations.push(value)

    return acc
  }, {})

  // Convert the object to an array and sort it so that categories with null are on top
  return Object.values(group).sort((a, b) => {
    if (a.category_id === null) return -1 // Place null categories on top
    if (b.category_id === null) return 1
    
    return 0 // Keep the rest in the same order
  })
}
</script>

<template>
  <section>
    <!--
      <VCol cols="12">
      <VCard
      title="Filters"
      class="mb-6"
      >
      <VCardText>
      <VRow>
      <VCol
      cols="12"
      sm="3"
      >
      <AppSelect
      v-model="selectedEscalationLevel"
      label="Select Escalation Level"
      placeholder="Select Escalation Level"
      :items="escalationLevels"
      :item-title="item => item.level"
      :item-value="item => item.id"  
      multiple  
      persistent-hint
      clearable
      clear-icon="tabler-x"
      />
      </VCol>
      <VCol
      cols="12"
      sm="3"
      >
      <AppAutocomplete
      v-model="selectedDepartments"
      label="Select Departments"
      placeholder="Select Departments"
      :items="departments"
      :item-title="item => item.name"
      :item-value="item => item.id"
      multiple
      clearable
      clear-icon="tabler-x"
      />
      </VCol>

      <VCol
      cols="12"
      sm="3"
      >
      <AppAutocomplete
      v-model="selectedCategories"
      label="Select Categories"
      placeholder="Select Categories"
      :items="categories"
      :item-title="item => item.name"
      :item-value="item => item.id"
      multiple
      clearable
      clear-icon="tabler-x"
      />
      </VCol>
      <VCol
      cols="12"
      sm="3"
      >
      <AppSelect
      v-model="selectedUnits"
      label="Select Units"
      placeholder="Select Units"
      :items="units"
      :item-title="item => item.name"
      :item-value="item => item.id"
      multiple
      clearable
      clear-icon="tabler-x"
      />
      </VCol>
      <VCol
      cols="12"
      sm="3"
      >
      <AppSelect
      v-model="selectedStatus"
      label="Select Status"
      placeholder="Select Status"
      :items="status"
      clearable
      clear-icon="tabler-x"
      />
      </VCol>
      </VRow>
      </VCardText>
      </VCard>
      </VCol> 
    -->
    <VCol cols="12">
      <VCard>
        <VCardText class="d-flex flex-wrap py-4 gap-4">
          <div class="me-3 d-flex gap-3">
            <AppSelect
              :model-value="itemsPerPage"
              :items="[
                { value: 10, title: '10' },
                { value: 25, title: '25' },
                { value: 50, title: '50' },
                { value: 100, title: '100' },
                { value: -1, title: 'All' },
              ]"
              style="inline-size: 6.25rem;"
              @update:model-value="itemsPerPage = parseInt($event, 10)"
            />
          </div>
          <VSpacer />

          <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
            <!-- 👉 Search  -->
            <div style="inline-size: 10rem;">
              <AppTextField
                v-model="searchQuery"
                placeholder="Search"
                density="compact"
              />
            </div>

            <!-- 👉 Export button -->
            <VBtn
              variant="tonal"
              color="secondary"
              prepend-icon="tabler-screen-share"
            >
              Export
            </VBtn>
            <RouterLink
              :to="{ name: 'app-global-escalation-create-update', params: { departmentId: 'create' } }"
              class="font-weight-medium text-link"
            >
              <VBtn 
                v-if="$can('create', 'configuration.escalation')"
                prepend-icon="tabler-plus"
              >
                Add Escalation
              </VBtn>
            </RouterLink>
          </div>
        </VCardText>

        <VDivider />

        <VDataTableServer
          v-model:items-per-page="itemsPerPage"
          v-model:page="page"
          :items="escalations"
          :items-length="totalEscalations"
          :headers="headers"
          class="text-no-wrap"
          expand-on-click
          @update:options="updateOptions"
        >
          <!-- User -->
          <template #item.user="{ item }">
            <div
              class="d-flex align-center cursor-pointer"
              :userData="item"
              @click="showUser(item)"
            >
              <VAvatar
                size="34"
                :variant="!item.avatar ? 'tonal' : undefined"
                :color="!item.avatar ? resolveUserRoleVariant(item.roles).color : undefined"
                class="me-3"
              >
                <VImg
                  v-if="item.avatar"
                  :src="item.avatar"
                />
                <span v-else>{{ avatarText(item.name) }}</span>
              </VAvatar>
              <div class="d-flex flex-column">
                <h6 class="text-base">
                  {{ item.name }}
                </h6>
                <span class="text-sm text-medium-emphasis">{{ item.employee_code }}</span>
              </div>
            </div>
          </template>

          <!-- 👉 Role -->
          <template #item.role="{ item }">
            <div class="d-flex align-center gap-4">
              <VAvatar
                :size="30"
                :color="resolveUserRoleVariant(item.roles).color"
                variant="tonal"
              >
                <VIcon
                  :size="20"
                  :icon="resolveUserRoleVariant(item.roles).icon"
                />
              </VAvatar>
              <span class="text-capitalize">{{ item.role }}</span>
            </div>
          </template>
          <!-- Status -->
          <template #item.status="{ item }">
            <VChip
              :color="resolveUserStatusVariant(item.status)"
              size="small"
              label
              class="text-capitalize"
            >
              {{ item.status }}
            </VChip>
          </template>

          <!-- Actions -->
          <template #item.actions="{ item }" />

          <template #item.levels="{ item }">
            <div class="d-flex flex-row">
              <VChip 
                v-for="level in item.escalations.slice(0, 3)" 
                :key="level" 
                pill 
                color="primary" 
                text-color="white"
                class="mr-4 cursor-pointer non-selectable"
              >
                {{ level.escalation_level.level }}
              </VChip>
              <VChip 
                pill 
                color="primary" 
                text-color="white" 
                class="cursor-pointer non-selectable"
              >
                ...
              </VChip>
            </div>
          </template>

          <!-- Expanded Row Data -->
          <template #expanded-row="slotProps">
            <tr class="v-data-table__tr evel-table-custom-bg-color">
              <td
                colspan="12"
                class="level-bg-color pb-4"
              >
                <VContainer 
                  v-for="(category, key) in groupByCategory(slotProps.item.escalations)"
                  :key="key"
                  class=""
                >
                  <div class="d-flex align-center">
                    <p class="me-auto text-h6 mt-4">
                      {{ category.category_name }}
                    </p>
                    <IconBtn
                      v-if="$can('delete', 'configuration.escalation')"
                      @click="() => {
                        showConfirmDialog({
                          departmentId: slotProps.item.id,
                          categoryId: category.category_id,
                        })
                      }"
                    >
                      <VIcon icon="tabler-trash" />
                    </IconBtn>
                    <IconBtn v-if="$can('update', 'configuration.escalation')">
                      <RouterLink
                        :to="{ name: 'app-global-escalation-create-update', params: { departmentId: slotProps.item.id, categoryId: category.category_id } }"
                        class="font-weight-medium text-link"
                      >
                        <VIcon icon="tabler-edit" />
                      </RouterLink>
                    </IconBtn>
                  </div>

                  <VCard variant="outlined w-100">
                    <VTable class="text-no-wrap">
                      <thead>
                        <tr>
                          <th>
                            Level
                          </th>
                          <th>
                            Type
                          </th>
                          <th>
                            Users
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in category.escalations.sort((a, b) => a.escalation_level_id - b.escalation_level_id)"
                          :key="item.dessert"
                        >
                          <td>
                            {{ item.escalation_level.level }}
                          </td>
                          <td>
                            {{ item.escalation_type }}
                          </td>
                          <td>
                            <div class="v-avatar-group">
                              <template
                                v-for="(user, index) in item.escalation_users"
                                :key="index"
                              >
                                <VAvatar
                                  size="36"
                                  :color="$vuetify.theme.current.dark ? '#4A5072' : '#f6f6f7'"
                                >
                                  <VTooltip
                                    activator="parent"
                                    location="end"
                                  >
                                    {{ user.name }}
                                  </VTooltip>
                                  <VImg 
                                    v-if="user.avatar"
                                    :src="user.avatar"
                                  />
                                  <span v-else>
                                    {{ avatarText(user.name) }}
                                  </span>
                                </VAvatar>
                              </template>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </VTable>
                  </VCard>
                </VContainer>
              </td>
            </tr>
          </template>
        </VDataTableServer>
      <!-- SECTION -->
      </VCard>  
    </VCol>
    <ShowUserDrawer
      v-model:isDrawerOpen="isShowUserDrawerVisible"
      :user-data="showUserDrawerData"
    />
    <ConfirmDialog
      v-model:isDialogVisible="isConfirmDialogVisible"
      confirmation-question="Are you sure?"
      :is-confirm-visible="false"
      :data="confirmDialogData"
      @confirm="deleteEscalation"
    />
  </section>
</template>

<style scoped>
  .level-bg-color {
    background-color: #f7f7f7;
  }

  .non-selectable {
    user-select: none !important;
  }
</style>
