<script setup>
import RoleCards from '@/views/apps/roles/RoleCards.vue'
import ShowUserDrawer from '@/views/apps/user/list/ShowUserDrawer.vue'
import { VDataTableServer } from 'vuetify/labs/VDataTable'


definePage({
  meta: {
    name: 'users',
    subject: 'master.user',
    action: 'read',
  },  
})


// 👉 Store
const searchQuery = ref('')
const selectedRole = ref()
const selectedDepartments = ref()
const selectedUnits = ref()
const selectedStatus = ref()

// Data table options
const itemsPerPage = ref(10)
const page = ref(1)
const sortBy = ref()
const orderBy = ref()


const updateOptions = options => {
  page.value = options.page
  sortBy.value = options.sortBy[0]?.key
  orderBy.value = options.sortBy[0]?.order
}

// Headers
const headers = [
  {
    title: 'User',
    key: 'user',
  },
  {
    title: 'Email',
    key: 'email',
  },
  {
    title: 'Role',
    key: 'role',
  },
 
  {
    title: 'Actions',
    key: 'actions',
    sortable: false,
  },
]

const {
  data: usersData,
  execute: fetchUsers,
} =  await useApi(createUrl('/masters/users', {
  query: {
    paged: 1,
    q: searchQuery,
    status: selectedStatus,
    departments: selectedDepartments,
    roles: selectedRole,
    units: selectedUnits,
    itemsPerPage,
    page,
    sort: sortBy,
    order_by: orderBy,
  },
}))

const users = computed(() => usersData.value?.data)
const totalUsers = computed(() => usersData.value?.meta?.total)

// 👉 search filters
const { data: departments } =await useApi(createUrl('/masters/departments'))
const { data: roles } =await useApi(createUrl('/masters/roles'))
const { data: units } = await useApi(createUrl('/masters/units'))

const status = [
  {
    title: 'Active',
    value: 1,
  },
  {
    title: 'Inactive',
    value: 0,
  },
]

const resolveUserRoleVariant = roles => {

  const roleNames = roles.map(role => role.name)

  if (roleNames.includes('admin'))
    return { color: 'primary', icon: 'tabler-shield-check' }
  if (roleNames.includes('user'))
    return { color: 'secondary', icon: 'tabler-user' }
  if (roleNames.includes('manager'))
    return { color: 'warning', icon: 'tabler-user' }
  else
    return { color: 'secondary', icon: 'tabler-user' }
}

const resolveUserStatusVariant = stat => {
  const statLowerCase = stat.toLowerCase()
  if (statLowerCase === 'pending')
    return 'warning'
  if (statLowerCase === 'active')
    return 'success'
  if (statLowerCase === 'inactive')
    return 'secondary'
  
  return 'primary'
}

const isShowUserDrawerVisible = ref(false)
const showUserDrawerData = ref()

const showUser = userData => {
  showUserDrawerData.value = userData
  isShowUserDrawerVisible.value = true
}

const deleteUser = async id => {
  await $api(`/masters/users/${ id }`, { method: 'DELETE' }).then(() => {
    fetchUsers()
  })
}
</script>

<template>
  <section>
    <VCol 
      v-if="$can('read', 'master.role')" 
      cols="12"
    >
      <h4 class="text-h4 mb-6">
        Roles List
      </h4>
      <RoleCards />
    </VCol>
    <VCol cols="12">
      <VCard
        title="Filters"
        class="mb-6"
      >
        <VCardText>
          <VRow>
            <!-- 👉 Select Role -->
            <VCol
              cols="12"
              sm="3"
            >
              <AppSelect
                v-model="selectedRole"
                label="Select Role"
                placeholder="Select Role"
                :items="roles"
                :item-title="item => item.name"
                :item-value="item => item.id"
                multiple  
                persistent-hint
                clearable
                clear-icon="tabler-x"
              />
            </VCol>
            <VCol
              cols="12"
              sm="3"
            >
              <AppSelect
                v-model="selectedDepartments"
                label="Select Departments"
                placeholder="Select Departments"
                :items="departments"
                :item-title="item => item.name"
                :item-value="item => item.id"
                multiple
                clearable
                clear-icon="tabler-x"
              />
            </VCol>
            <VCol
              cols="12"
              sm="3"
            >
              <AppSelect
                v-model="selectedUnits"
                label="Select Units"
                placeholder="Select Units"
                :items="units"
                :item-title="item => item.name"
                :item-value="item => item.id"
                multiple
                clearable
                clear-icon="tabler-x"
              />
            </VCol>
            <VCol
              cols="12"
              sm="3"
            >
              <AppSelect
                v-model="selectedStatus"
                label="Select Status"
                placeholder="Select Status"
                :items="status"
                clearable
                clear-icon="tabler-x"
              />
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VCol>
    <VCol cols="12">
      <h4 class="text-h4 mb-6">
        Users List
      </h4>
      <VCard>
        <VCardText class="d-flex flex-wrap py-4 gap-4">
          <div class="me-3 d-flex gap-3">
            <AppSelect
              :model-value="itemsPerPage"
              :items="[
                { value: 10, title: '10' },
                { value: 25, title: '25' },
                { value: 50, title: '50' },
                { value: 100, title: '100' },
                { value: -1, title: 'All' },
              ]"
              style="inline-size: 6.25rem;"
              @update:model-value="itemsPerPage = parseInt($event, 10)"
            />
          </div>
          <VSpacer />

          <div class="app-user-search-filter d-flex align-center flex-wrap gap-4">
            <!-- 👉 Search  -->
            <div style="inline-size: 10rem;">
              <AppTextField
                v-model="searchQuery"
                placeholder="Search"
                density="compact"
              />
            </div>

            <!-- 👉 Export button -->
            <VBtn
              variant="tonal"
              color="secondary"
              prepend-icon="tabler-screen-share"
            >
              Export
            </VBtn>

            <!-- 👉 Add user button -->
            <RouterLink
              v-if="$can('create', 'master.user')"
              :to="{ name: 'app-user-add'}"
              class="font-weight-medium text-link"
            >
              <VBtn prepend-icon="tabler-plus">
                Add New User
              </VBtn>
            </RouterLink>
          </div>
        </VCardText>

        <VDivider />

        <!-- SECTION datatable -->
        <VDataTableServer
          v-model:items-per-page="itemsPerPage"
          v-model:page="page"
          :items="users"
          :items-length="totalUsers"
          :headers="headers"
          class="text-no-wrap"
          @update:options="updateOptions"
        >
          <!-- User -->
          <template #item.user="{ item }">
            <div
              class="d-flex align-center cursor-pointer"
              :userData="item"
              @click="showUser(item)"
            >
              <VAvatar
                size="34"
                :variant="!item.avatar ? 'tonal' : undefined"
                :color="!item.avatar ? resolveUserRoleVariant(item.roles).color : undefined"
                class="me-3"
              >
                <VImg
                  v-if="item.avatar"
                  :src="item.avatar"
                />
                <span v-else>{{ avatarText(item.name) }}</span>
              </VAvatar>
              <div class="d-flex flex-column">
                <h6 class="text-base">
                  {{ item.name }}
                </h6>
                <span class="text-sm text-medium-emphasis">{{ item.employee_code }}</span>
              </div>
            </div>
          </template>

          <!-- 👉 Role -->
          <template #item.role="{ item }">
            <div class="d-flex align-center gap-4">
              <VAvatar
                :size="30"
                :color="resolveUserRoleVariant(item.roles).color"
                variant="tonal"
              >
                <VIcon
                  :size="20"
                  :icon="resolveUserRoleVariant(item.roles).icon"
                />
              </VAvatar>
              <span class="text-capitalize">{{ item.role }}</span>
            </div>
          </template>
          <!-- Status -->
          <template #item.status="{ item }">
            <VChip
              :color="resolveUserStatusVariant(item.status)"
              size="small"
              label
              class="text-capitalize"
            >
              {{ item.status }}
            </VChip>
          </template>

          <!-- Actions -->
          <template #item.actions="{ item }">
            <IconBtn
              v-if="$can('delete', 'master.user')"
              @click="deleteUser(item.id)"
            >
              <VIcon icon="tabler-trash" />
            </IconBtn>
            <IconBtn v-if="$can('update', 'master.user')">
              <RouterLink
                :to="{ name: 'app-global-user-tab', params: { id: item.id,tab:'account' } }"
                class="font-weight-medium text-link"
              >
                <VIcon icon="tabler-edit" />
              </RouterLink>
            </IconBtn>
          </template>
        </VDataTableServer>
      <!-- SECTION -->
      </VCard>  
    </VCol>
    <ShowUserDrawer
      v-model:isDrawerOpen="isShowUserDrawerVisible"
      :user-data="showUserDrawerData"
    />
  </section>
</template>
