<script setup>
import { userAuthState } from "@/@core/auth"
import { VNodeRenderer } from "@layouts/components/VNodeRenderer"
import { themeConfig } from "@themeConfig"
import { computed } from "vue"

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: false,
  },
})

const auth = userAuthState()

// const modules = [
//   {
//     title: "Feedback",
//     desc: "Feedback Description",
//     icon: "tabler-clipboard-text",
//     to: '/app/feedback/dashboard'
//   },
//   {
//     title: "Incident",
//     desc: "Incident Description",
//     icon: "tabler-traffic-cone",
//     to: '/app/incident/dashboard'
//   },
// ];

const computedModules = computed(() => {
  let data = []
  auth?.user?.modules?.forEach(element => {
    data = [
      ...data,
      MODULES.find(x => x.name === element.name),
    ]
  })
  data = [
    ...data,
    {
      name: "Global Settings",
      desc: "Manage global settings",
      icon: "tabler-settings",
      to: '/app/global/users',
    },
  ]
  
  return data
})
</script>

<template>
  <VRow class="mt-8">
    <VCol cols="12">
      <div class="d-flex align-center justify-center w-100 deal-type-image-wrapper px-5 pt-2 pb-5">
        <VNodeRenderer
          :nodes="themeConfig.app.client.logo"
          class="mb-6"
        />
      </div>
    </VCol>
  </VRow>

  <div 
    v-if="auth.user.modules?.length !== 0"
    class="help-center-section bg-surface"
  >
    <VContainer>
      <h4 class="text-h4 text-center mb-6">
        Panel Modules
      </h4>

      <VRow>
        <VCol
          v-for="module in computedModules"
          :key="module.name"
          cols="12"
          md="4"
        >
          <RouterLink :to="module.to">
            <VCard
              class="cursor-pointer hover-border"
              flat
            >
              <VCardText class="align-center text-center d-flex flex-column gap-3">
                <VIcon
                  size="40"
                  :icon="module.icon"
                  class="text-high-emphasis"
                />

                <h5 class="text-h5">
                  {{ module.name }}
                </h5>
                <p class="text-body-1 mb-0">
                  {{ module.desc }}
                </p>

                <!--
                  <VBtn
                  size="small"
                  variant="tonal"
                  :to="module.to"
                  >
                  Go To Panel
                  </VBtn> 
                -->
              </VCardText>
            </VCard>
          </RouterLink>
        </VCol>
      </VRow>
    </VContainer>
  </div>
  <div v-else>
    <VContainer>
      <h4 class="text-h4 text-center mb-6">
        You don't have access to these modules.<br>Please contact with admin.
      </h4>
    </VContainer>
  </div>
</template>

<style scoped>
.hover-border {
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.hover-border:hover {
  border-color: blue;
}
</style>
