<script setup>
import { cookieRef } from '@/@layouts/stores/config'
import { IncidentStatus } from '@/enums/incident/incident.status'
import IncidentService from '@/services/incident/incident.service'
import { useRoute, useRouter } from 'vue-router'
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'
import { RcaStatus, rcaStatusColor } from '@/enums/incident/rca.status'

import RcaDrawer from '@/views/incident/incident-panel/Rca.vue'
import IncidentDetailsView from "@/views/incident/incident-panel/views/Details.vue"
import InvolvedPartiesView from "@/views/incident/incident-panel/views/InvolvedParties.vue"
import IncidentQuestionsView from "@/views/incident/incident-panel/views/Questions.vue"

import { titleCase } from '@/@core/utils/formatters'
import { resolveIncidentTypeIconColor } from '@/@core/utils/helpers'
import { VRadio } from 'vuetify/lib/components/index.mjs'
import { watch } from 'vue'

definePage({
  meta: {
    name: "incident-view",
    subject: "incident.incident",
    action: "read",
  },
})

const route = useRoute()
const router = useRouter()

const unitId = ref(cookieRef('selectedUnit') ?? null)

const incident = ref(null)

await useApi(createUrl(`/incidents/incidents/${route.params.id}`, {}), {
  afterFetch: ({ data }) => {
    incident.value = data
  },
  onFetchError: error => {
    router.push({
      name: 'invalid-url',
      params: { code: 404 }, 
    })
  },
})


const {
  data: rcaListing,
  execute: fetchRcaListing,
} = await useApi(createUrl('incidents/incident-rcas', {
  query: {
    search: {
      incident_id: incident.value?.id,
    },
  },
}))

const refetchIncident = async () => {
  useApi(createUrl(`/incidents/incidents/${route.params.id}`, {}), {
    afterFetch: ({ data }) => {
      incident.value = data
    },
  })
  fetchRcaListing()
}

// Perfect scrollbar
const chatLogPS = ref()

const scrollToBottomInChatLog = () => {
  const scrollEl = chatLogPS.value.$el || chatLogPS.value

  scrollEl.scrollTop = scrollEl.scrollHeight
}

const rca = ref()
const isRcaDrawer = ref(false)

watch(isRcaDrawer, value => {
  if (value === false) rca.value = null
}, { deep: true })

const isApprovalDialog = ref(false)
const approvalForm = ref({})
const typeOfIncidentOptions = ref([])

await useApi(createUrl('/masters/incident-types', {}), {
  afterFetch: ({ data }) => {
    typeOfIncidentOptions.value = data.map(item => {
      return {
        ...item,
        icon: "tabler-accessible",
      }
    })
  },
})

const {
  data: classificationOptions,
} = await useApi(createUrl('/masters/tags', {
  query: {
    search: {
      unit_id: unitId.value,
      tag_type: 'EVENT_CLASSIFICATION',
    },
  },
}))

const submitApproval = async () => {
  await IncidentService.approve(
    route.params.id, 
    {
      unit_id: unitId.value,
      ...approvalForm.value,
    },
    data => {
      //Update data
      incident.value.incident_status = data.incident_status
      incident.value.incident_type = data.incident_type
      incident.value.event_classification = data.event_classification

      isApprovalDialog.value = false
    },
    error => {
      //
    },
  )
}

const isRejectionDialog = ref(false)
const rejectionForm = ref({})
const rejectionTypeOptions = await getEnumValue('RejectionTypes')

const submitRejectionForm = async () => {

  await IncidentService.reject(
    route.params.id, 
    {
      unit_id: unitId.value,
      ...rejectionForm.value,
    },
    data => {
      //Update data
      incident.value.incident_status = data.incident_status
      incident.value.rejection_type = data.rejection_type
      incident.value.rejection_note = data.rejection_note
      
      isRejectionDialog.value = false
    },
    error => {
      //
    },
  )
}

const review = async () => {
  await IncidentService.review(route.params.id, {
    unit_id: unitId.value,
    incident_status: IncidentStatus.DRAFT,
  }, data => {
    //Update data
    incident.value.incident_status = data.incident_status

    // Redirection
    router.push({
      name: 'app-incident-entry',
      params: { id: incident.value.id },
    })
  }, error => {
    //
  },
  )
}
</script>

<template>
  <div class="mt-4">
    <VContainer fluid>
      <VRow>
        <VCol
          cols="12"
          md="8"
        >
          <div class="d-md-flex align-center justify-space-between">
            <div class="text-overline">
              <h1>{{ incident.code }}</h1>
            </div>
            <!-- sub title -->
            <div class="text-body-1 off">
              <b>Incident Datetime:</b>
              {{ incident.incident_datetime }}
            </div>
          </div>
        </VCol>
      </VRow>
      <VRow justify="space-around">
        <VCol
          
          md="8"
          sm="12"
          order="1"
        >
          <IncidentDetailsView :data="incident" />
          <InvolvedPartiesView :data="incident" />
          <IncidentQuestionsView :data="incident" />
        </VCol>
  
      
        <VCol
          cols="12"
          md="4"
          order-sm="1"
        >
          <div class="pl-md-8 pl-sm-0">
            <!-- status chip -->
            <div class="text-h5 d-none d-sm-flex mb-5">
              <VChip
                class="pa-2 block-chip"
                variant="elevated"
                :color="resolveIncidentStatusColor(incident.incident_status)"
                size="20"
              >
                <div class="px-15">
                  {{ titleCase(incident.incident_status) }}
                </div>
              </VChip>
            </div>


            <!-- Approval or reject -->
            <VCard
              v-if="$can('verification', 'incident.incident') &&
                incident.incident_status === IncidentStatus.REQUEST_SUBMITTED
              "
            >
              <VCardItem>
                <div class="text-h4">
                  Approval
                </div>
                <div>
                  You have the option to approve the incident, reject it, or engage in a
                  discussion.
                </div>
              </VCardItem>
  
              <VCardText 
                v-if="$can('verification', 'incident.incident')"
                class="mt-2"
              >
                <VRow>
                  <VCol
                    v-if="incident.incident_status === IncidentStatus.REQUEST_SUBMITTED"
                    cols="6"
                  >
                    <VBtn
                      color="primary"
                      block
                      @click="isApprovalDialog = true"
                    >
                      Approve
                    </VBtn>
                  </VCol>
                  <VCol
                    v-if="incident.incident_status === IncidentStatus.REQUEST_SUBMITTED"
                    cols="6"
                  >
                    <VBtn
                      block
                      color="error"
                      @click="isRejectionDialog = true"
                    >
                      Reject
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
  
            <!-- delegation -->
            <VCard
              v-else-if="
                incident.incident_status === IncidentStatus.DELEGATION_PENDING ||
                  incident.incident_status === IncidentStatus.RCA_PENDING
              "
            >
              <VCardText>
                <VRow>
                  <VCol cols="12">
                    <VList>
                      <VListItem>
                        <template #prepend>
                          <div class="text-h6">
                            Incident Type:
                          </div>
                        </template>
                        <VListItemTitle>
                          <div>
                            <VChip
                              class=" pa-2 ml-3"
                              size="medium"
                              :color="resolveIncidentTypeIconColor(incident.incident_type?.name).color"
                            >
                              <VIcon
                                start
                                :icon="resolveIncidentTypeIconColor(incident.incident_type?.name).icon"
                              />
                              {{ incident.incident_type?.name }}
                            </VChip>
                          </div>
                        </VListItemTitle>
                      </VListItem>

                      <VListItem>
                        <VListItemTitle class="text-wrap mt-2">
                          <div class="text-h6">
                            Event Classification
                          </div>
                          <VAlert
                            border
                            class="text-wrap mt-2"
                          >
                            {{ incident.event_classification?.name }}
                          </VAlert>
                        </VListItemTitle>
                      </VListItem>
                    </VList>
                  </VCol>
                </VRow>
              
                <VRow v-if="$can('create', 'incident.incident_rca')">
                  <VCol>
                    <VBtn 
                      block
                      @click="isRcaDrawer = true"
                    >
                      Delegate
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
  
            <!-- rejected -->
            <VCard v-else-if="incident.incident_status === IncidentStatus.REJECTED">
              <VCardText>
                <div>
                  The incident didn't matched the quality criteria please review the changes requested by quality team.
                </div>
                <VRow class="mt-2">
                  <VCol cols="4">
                    Rejection Type:
                  </VCol>
                  <VCol cols="8">
                    <VChip
                      class="text-h6 pa-2"
                      size="30"
                      :color="resolveIncidentRejectionStatusColor( incident.rejection_type)"
                    >
                      {{ titleCase(incident.rejection_type) }}
                    </VChip>
                  </VCol>
                </VRow>
                <VRow>
                  <VCol cols="4">
                    Rejection Remarks:
                  </VCol>
                  <VCol cols="8">
                    {{ incident.rejection_note }}
                  </VCol>
                </VRow>
                <VRow>
                  <VCol>
                    <VBtn 
                      v-if="$can('verification', 'incident.incident') && incident.incident_status === IncidentStatus.REJECTED"
                      color="primary"
                      block
                      @click="review"
                    >
                      Review & Draft
                    </VBtn>
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
  
            <!-- RCA Listing -->
            <VCard 
              v-if="incident.incident_status !== IncidentStatus.DRAFT &&
                incident.incident_status !== IncidentStatus.REQUEST_SUBMITTED &&
                incident.incident_status !== IncidentStatus.REJECTED
              "
              class="mt-6"
            >
              <VCardText v-if="rcaListing?.length">
                <div class=" mb-4 d-md-flex  align-center">
                  <div class="text-h5 flex-1-0 pr-5">
                    Investigation List
                  </div>
                
                  <VDivider thickness="2" />
                </div>
                <PerfectScrollbar
                  ref="chatLogPS"
                  :options="{ wheelPropagation: false }"
                  style="
                  padding: 0 !important;
                  max-block-size: 50vh !important;"
                >
                  <div>
                    <div
                      v-for="(item, index) in rcaListing"
                      :key="index"
                    >
                      <div>
                        <div class="d-flex align-center justify-space-between">
                          <div class="text-h6">
                            {{ index + 1 }}. {{ item.title }} <br>
                            <p class="text-body-1 text-grey">
                              {{ formatDate(item.created_at ) }}
                            </p>
                          </div>
                          <div class="d-flex gap-x-4">
                            <VBtn 
                              v-if="$can('update', 'incident.incident_rca') && item.rca_status === RcaStatus.PENDING"
                              color="primary"
                              @click="() => {
                                rca = item
                                isRcaDrawer = true
                              }"
                            >
                              <VIcon 
                                size="22"
                                icon="tabler-edit"
                              />
                            </VBtn>
                            <VChip
                              class="pa-2"
                              size="20"
                              :color="rcaStatusColor(item.rca_status)"
                            >
                              {{ titleCase(item.rca_status) }}
                            </VChip>
                          </div>
                        </div>
                        <!-- users & group -->
                   
                        <div>
                          <VContainer>
                            <p class="text-h6 mb-6">
                              Users and Groups
                            </p>
                            
                            <VRow>
                              <!-- Users Column -->
                              <VCol
                                v-if="item?.user_delegates?.length"
                                cols="12"
                                :md="item?.group_delegates?.length ? 6 : 12"
                              >
                                <VList>
                                  <VListItem
                                    v-for="userDelegate in item?.user_delegates"
                                    :key="userDelegate?.id"
                                    class="mb-2"
                                  >
                                    <VCard
                                      elevation="1"
                                      class="mb-4"
                                    >
                                      <VCardText>
                                        <template #prepend>
                                          <VAvatar
                                            color="primary"
                                            class="text-white"
                                          >
                                            {{ userDelegate?.delegate?.name.charAt(0) }}
                                          </VAvatar>
                                        </template>
                          
                                        <VListItemTitle class="text-h6">
                                          {{ userDelegate?.delegate?.name }}
                                        </VListItemTitle>
                                        <VListItemSubtitle>
                                          {{ userDelegate?.delegate?.employee_code }}
                                        </VListItemSubtitle>
                                      </VCardText>
                                    </VCard>
                                  </VListItem>
                        
                                  <VBtn
                                    v-if="item?.user_delegates?.length > 3"
                                    variant="text"
                                    block
                                    class="mt-2"
                                    prepend-icon="tabler-down"
                                  >
                                    More
                                  </VBtn>
                                </VList>
                              </VCol>
                              <VDivider
                                v-if="item?.user_delegates?.length"
                                vertical="false"
                              />

                        
                              <!-- Groups Column -->
                              <VCol
                                v-if="item?.group_delegates?.length"
                                cols="12"
                                :md="item?.user_delegates?.length ? 6 : 12"
                              >
                                <VList>
                                  <template
                                    v-for="groupDelegate in item?.group_delegates"
                                    :key="groupDelegate.id"
                                  >
                                    <VListSubheader class="text-h6 pa-1">
                                      {{ groupDelegate.delegate.name }}
                                    </VListSubheader>
                                    <VDivider />
                                    <VListItem>
                                      <div>
                                        <VAvatar
                                          color="primary"
                                          class="text-white"
                                        >
                                          {{ groupDelegate.delegate.users[0].name.charAt(0) }}
                                        </VAvatar>
                                        <VListItemTitle class="text-h6">
                                          {{ groupDelegate.delegate.users[0].name }}
                                        </VListItemTitle>
                                        <VListItemSubtitle>
                                          {{ groupDelegate.delegate.users[0].employee_code }}
                                        </VListItemSubtitle>
                                      </div>

                      
                      
                                      <template #append>
                                        <VMenu :close-on-content-click="false">
                                          <template #activator="{ props }">
                                            <VBtn
                                              variant="text"
                                              prepend-icon="tabler-down"
                                              v-bind="props"
                                            >
                                              +{{ groupDelegate.delegate.users.length - 1 }}
                                            </VBtn>
                                          </template>
                                      
                                          <!-- Menu Content -->
                                          <VList>
                                            <VListItem
                                              v-for="(user, i) in groupDelegate.delegate.users.slice(1)"
                                              :key="i"
                                            >
                                              <template #prepend>
                                                <VAvatar
                                                  color="primary"
                                                  class="text-white"
                                                >
                                                  {{ user.name.charAt(0) }}
                                                </VAvatar>
                                              </template>
                                      
                                              <VListItemTitle>{{ user.name }}</VListItemTitle>
                                              <VListItemSubtitle>{{ user.employee_code }}</VListItemSubtitle>
                                            </VListItem>
                                          </VList>
                                        </VMenu>
                                      </template>
                                    </VListItem>
                                  </template>
                                </VList>
                              </VCol>
                            </VRow>
                          </VContainer>
                        </div>
                        <!-- end users & group -->

                        <!-- note to delege info -->
                        <VAlert
                          density="compact"
                          color="info"
                          variant="tonal"
                        >
                          <div class="text-h6 text-info">
                            Note to Delegates
                          </div>
                          <p>
                            {{ item.delegation_notes }}
                          </p>
                        </VAlert>
                       
                        <VDivider
                          v-if="rcaListing.length - 1 !== index"
                          class="mt-4 mb-4"
                        />
                      </div>
                    </div>
                  </div>
                </PerfectScrollbar>
              </VCardText>
            </VCard>

            <!-- Reporter Details -->
            <VCard class="mt-5">
              <div class="d-md-flex  align-center pa-5">
                <div class="text-h6 flex-1-0 pr-5">
                  Reported User Details
                </div>
              
                <VDivider thickness="2" />
              </div>
 
              <VCardText class="mt-0 pt-0">
                <div>
                  <div
                    v-if="incident.reported_by?.employee_code"
                    class="mb-2"
                  >
                    <VLabel>Employee Code</VLabel><br>
                    <VChip
                      size="20"
                      class="pa-3 block-chip justify-items-start"
                      :label="false"
                      color="primary"
                    >
                      {{ incident.reported_by?.employee_code }}
                    </VChip>
                  </div>
                  <div
                    v-if="incident.reported_by?.name"
                    class="mb-2"
                  >
                    <VLabel>Name</VLabel><br>
                    <VChip
                      size="20"
                      class="pa-3 block-chip justify-items-start"
                      :label="false"
                      color="primary"
                    >
                      {{ incident.reported_by?.name }}
                    </VChip>
                  </div>
                  <div
                    v-if="incident.reported_by?.email"
                    class="mb-2"
                  >
                    <VLabel>Email</VLabel><br>
                    <VChip
                      size="20"
                      class="pa-3 block-chip justify-items-start"
                      :label="false"
                      color="primary"
                    >
                      {{ incident.reported_by?.email }}
                    </VChip>
                  </div>
                  <div
                    v-if="incident.reporter?.phone"
                    class="mb-2"
                  >
                    <VLabel>Contact Number</VLabel><br>
                    <VChip
                      size="20"
                      class="pa-3 block-chip justify-items-start"
                      :label="false"
                      color="primary"
                    >
                      {{ incident.reporter?.phone }}
                    </VChip>
                  </div>
                </div>
              </VCardText>
            </VCard>
            <!-- end reporter details -->

            <!-- Incident activites -->
            <VCard class="mt-5">
              <div class="d-md-flex  align-center pa-5">
                <div class="text-h6 flex-1-0 pr-5">
                  Incident Activities
                </div>
              
                <VDivider thickness="2" />
              </div>
 
              <VCardText class="mt-0 pt-0">
                <div>
                  <!-- timeline -->
                  <VTimeline>
                    <VTimelineItem
                      v-for="(activity, index) in incident.activities"
                      :key="index"
                    >
                      <VTimelineItemIcon>
                        <VIcon
                          :color="activity.color"
                          :icon="activity.icon"
                        />
                      </VTimelineItemIcon>
                      <VTimelineItemContent>
                        <div class="text-h6">
                          {{ activity.title }}
                        </div>
                        <div class="text-body-1">
                          {{ activity.description }}
                        </div>
                        <div class="text-body-2">
                          {{ formatDate(activity.created_at) }}
                        </div>
                      </VTimelineItemContent>
                    </VTimelineItem>
                  </VTimeline>
                </div>
              </VCardText>
            </VCard>
          </div>
  
          <!--
            <VCard class="mt-6">
            <ChatBox />
            </VCard> 
          -->
        </VCol>
      </VRow>
    </VContainer>
  </div>

  <RcaDrawer
    v-model:isDrawerOpen="isRcaDrawer"
    :incident="incident"
    :rca="rca"
    @fetch-rca-listing="refetchIncident"
  />

  <!-- Approval Dialog -->
  <VDialog
    v-model="isApprovalDialog"
    max-width="800"
  >
    <template #default="{ }">
      <VCard title="Approval Form">
        <VCardText>
          <div class="d-flex flex-column gap-4">
            <!--
              <AppSelect
              v-model="approvalForm.incident_type_id"
              :items="typeOfIncidentOptions"
              :item-title="(item) => item.name"
              :item-value="(item) => item.id"
              placeholder="Select Type"
              label="Type of Incident"
              :return-object="false"
              :required="true"
              /> 
            -->

            <div>
              <VLabel
                for="incident_type_id"
                text="Type of Incident"
              >
                <span class="text-error">*</span>
              </VLabel>
              <CustomRadiosWithIcon
                v-model:selected-radio="approvalForm.incident_type_id"
                class="my-2"
                hide-radio
                :radio-content="typeOfIncidentOptions"
                :keys="{
                  value: 'id',
                  title: 'name',
                  desc: 'description',
                  // icon: 'icon',
                }"
                :grid-column="{ cols: '4', md: '6', sm: '2', xs: '6' }"
                :rules="[requiredValidator]"
              />
            </div>

            <!--
              <AppSelect
              v-model="approvalForm.event_classification_id"
              :items="classificationOptions"
              :item-title="(item) => item.name"
              :item-value="(item) => item.id"
              placeholder="Select Option"
              label="Classification"
              :return-object="false"
              :required="true"
              /> 
            -->

            <div>
              <VLabel
                for="event_classification_id"
                text="Classification"
              >
                <span class="text-error">*</span>
              </VLabel>
              <VRadioGroup
                v-model="approvalForm.event_classification_id"
                :inline="false"
              >
                <VRadio 
                  v-for="(option, index) in classificationOptions"
                  :key="index"
                  :label="option.name"
                  :value="option.id"
                />
              </VRadioGroup>
            </div>
          </div>

          <div class="d-flex justify-end gap-4 mt-6">
            <VBtn
              variant="tonal"
              color="error"
              @click="isApprovalDialog = false"
            >
              Cancel
            </VBtn>
            <VBtn
              variant="tonal"
              @click="submitApproval"
            >
              Approve
            </VBtn>
          </div>
        </VCardText>
      </VCard>
    </template>
  </VDialog>

  <!-- Rejection Form -->
  <VDialog
    v-model="isRejectionDialog"
    max-width="500"
  >
    <template #default="{ }">
      <VCard title="Rejection Form">
        <VCardText>
          <div class="d-flex flex-column gap-4">
            <AppSelect
              v-model="rejectionForm.rejection_type"
              :items="rejectionTypeOptions"
              :item-title="(item) => item.title"
              :item-value="(item) => item.value"
              placeholder="Select Type"
              label="Rejection Type"
              :return-object="false"
              required
            />

            <AppTextarea
              v-model="rejectionForm.rejection_note"
              rows="4"
              label="Note"
              placeholder="Write reason for rejection"
              :return-object="false"
              required
            />
          </div>

          <div class="d-flex justify-end gap-4 mt-6">
            <VBtn
              variant="tonal"
              color="error"
              @click="isRejectionDialog = false"
            >
              Cancel
            </VBtn>
            <VBtn
              variant="tonal"
              @click="submitRejectionForm"
            >
              Reject
            </VBtn>
          </div>
        </VCardText>
      </VCard>
    </template>
  </VDialog>
</template>
