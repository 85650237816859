<script>
export const dateRangeConfig = () => {
  return {
    mode: "range",
    maxDate: new Date(),
    dateFormat: "Y-m-d",
    enableTime: false,
    enableSeconds: false,
    noCalendar: false,
    altInput: true,
    altFormat: "F j, Y",
    altInputClass: "form-control",
    altInputPlaceholder: "Select date",
    inline: false,
    static: false,
    appendTo: document.body,
    clickOpens: true,
    defaultDate: [new Date(), new Date()],
  }
}
</script>

<script setup>
import { addDays, addMonths, customDateFormat } from "@/@layouts/utils"
import Analytics from "./analytics.vue"

definePage({
  meta: {
    name: "incident-dashboard",
    subject: "dashboard.incident",
    action: "read",
  },
})

// const canAccessUnit = can('read', 'masters.unit')

const donutChartColors = {
  donut: {
    series1: "#22A95E",
    series2: "#24B364",
    series3: "#56CA00",
    series4: "#53D28C",
    series5: "#7EDDA9",
    series6: "#A9E9C5",
  },
}

const timeSpendingChartConfig = {
  chart: {
    height: 157,
    width: 130,
    parentHeightOffset: 0,
    type: "donut",
  },
  labels: ["No harm", "Near miss event", "Adverse", "Sentinel"],
  colors: [
    donutChartColors.donut.series1,
    donutChartColors.donut.series2,
    donutChartColors.donut.series3,
    donutChartColors.donut.series4,
  ],
  stroke: { width: 0 },
  dataLabels: {
    enabled: false,
    formatter(val) {
      return `${Number.parseInt(val)}%`
    },
  },
  legend: { show: false },
  tooltip: { theme: false },
  grid: { padding: { top: 0 } },
  plotOptions: {
    pie: {
      donut: {
        size: "75%",
        labels: {
          show: true,
          value: {
            fontSize: "1.125rem",
            color:
              "rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity))",
            fontWeight: 500,
            offsetY: -15,
            formatter(val) {
              return `${Number.parseInt(val)}%`
            },
          },
          name: { offsetY: 20 },
          total: {
            show: true,
            fontSize: "15px",
            label: "Total",
            color:
              "rgba(var(--v-theme-on-background), var(--v-disabled-opacity))",
            formatter() {
              return "16"
            },
          },
        },
      },
    },
  },
}

const timeSpendingChartSeries = [4, 8, 2, 4]

const quickDateRange = ref("Last 30 days")

const quickDateRangeOptions = [
  {
    title: "Today",
    value: `${customDateFormat(new Date())}`,
  },
  {
    title: "Yesterday",
    value: `${customDateFormat(addDays(new Date(), -1))}`,
  },
  {
    title: "Last 7 days",
    value: `${customDateFormat(addDays(new Date(), -6))} to ${customDateFormat(
      new Date(),
    )}`,
  },
  {
    title: "Last 30 days",
    value: `${customDateFormat(addDays(new Date(), -30))} to ${customDateFormat(
      new Date(),
    )}`,
  },
  {
    title: "This month",
    value: `${customDateFormat(startOfMonth(new Date()))} to ${customDateFormat(
      new Date(),
    )}`,
  },
  {
    title: "Last month",
    value: `${customDateFormat(
      startOfMonth(addMonths(new Date(), -1)),
    )} to ${customDateFormat(endOfMonth(addMonths(new Date(), -1)))}`,
  },
  {
    title: "Custom",
    value: null,
  },
]

const setQuickDateRange = value => {
  dateRange.value = value?.value
}

const dateRangeOptions = ref({
  ...dateRangeConfig(),
  onChange: (selectedDates, dateStr, instance) => {
    fromDate.value = customDateFormat(selectedDates[0])
    if (selectedDates[1]) toDate.value = customDateFormat(selectedDates[1])
    else {
      toDate.value = customDateFormat(selectedDates[0])
    }
    dateRange.value = dateStr
  },
  defaultDate: [
    customDateFormat(addDays(new Date(), -30)),
    customDateFormat(new Date()),
  ],
})

const dateRange = ref()
const unitId = ref()
</script>

<template>
  <!--
    <VRow>
    <VCol cols="12">
    <span class="text-h3 font-weight-bold text-primary text-capitalize">
    Dashboard
    </span>
    </VCol>
    </VRow> 
  -->
  <VRow class="mb-4">
    <VCol
      md="12"
      sm="12"
      xs="12"
    >
      <VCard>
        <VCardText>
          <span class="text-h6 font-weight-bold text-primary text-capitalize">
            Filter Dashboard By Date
          </span>
          <VRow align="center">
            <VCol
              cols="12"
              md="4"
              sm="12"
              xs="4"
            >
              <AppSelect
                v-model="quickDateRange"
                label="Quick Range"
                return-object
                :item-title="(item) => item.title"
                :item-value="(item) => item.title"
                :items="quickDateRangeOptions"
                @update:model-value="setQuickDateRange"
              />
            </VCol>
            <VCol
              cols="12"
              md="4"
              sm="12"
              xs="12"
            >
              <AppDateTimePicker
                v-model="dateRange"
                label="Range"
                placeholder="Select date"
                :config="dateRangeOptions"
              />
            </VCol>
            <VCol
              cols="12"
              md="4"
              sm="12"
              xs="12"
            >
              <AppSelect
                v-model="unitId"
                clearable
                label="Select Unit"
                placeholder="Select Unit"
                :items="[]"
                :item-title="(item) => item.name"
                :item-value="(item) => item.id"
              />
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>

  <VRow class="py-6">
    <!-- 👉 Welcome -->
    <VCol
      cols="12"
      md="6"
      :class="$vuetify.display.mdAndUp ? 'border-e' : 'border-b'"
    >
      <div class="pe-3">
        <VRow>
          <VCol
            v-for="{ title, value, subValue, icon, color } in [
              {
                title: 'Total Incidents',
                icon: 'tabler-traffic-cone',
                color: 'primary',
                value: 144,
              },
              {
                title: 'Preventability Incidents',
                icon: 'tabler-presentation',
                color: 'info',
                value: 26,
              },
              {
                title: 'Clinical Incidents',
                icon: 'tabler-first-aid-kit',
                color: 'success',
                value: 14,
              },
              {
                title: 'Non-Clinical Incidents',
                icon: 'tabler-first-aid-kit-off',
                color: 'warning',
                value: 56,
              },
            ]"
            :key="title"
            cols="12"
            md="6"
            sm="6"
          >
            <div class="d-flex align-center">
              <VAvatar
                variant="tonal"
                :color="color"
                rounded
                size="54"
                class="text-primary me-4"
              >
                <VIcon
                  :icon="icon"
                  size="38"
                />
              </VAvatar>
              <div>
                <h6 class="text-h6 text-medium-emphasis">
                  {{ title }}
                </h6>
                <div class="d-flex gap-4">
                  <h4
                    class="text-h4"
                    :class="`text-${color}`"
                  >
                    {{ value }}
                  </h4>
                  <VChip
                    v-if="subValue"
                    class="mt-1"
                    label
                    size="small"
                    :color="isNagativeNumber(subValue) ? 'error' : 'success'"
                  >
                    <VIcon
                      :icon="
                        isNagativeNumber(subValue)
                          ? 'tabler-arrow-down'
                          : 'tabler-arrow-up'
                      "
                      class="mr-1"
                    />
                    {{ subValue }}%
                  </VChip>
                </div>
              </div>
            </div>
          </VCol>
        </VRow>
      </div>
    </VCol>
    <!-- Ticket Type -->
    <VCol
      cols="12"
      md="6"
    >
      <div class="ps-3 d-flex flex-row justify-space-between align-center">
        <div class="me-auto">
          <VRow no-gutters>
            <VCol
              v-for="type in [
                {
                  name: 'No harm',
                  count: 4,
                  icon: 'tabler-heart',
                  color: 'success',
                },
                {
                  name: 'Near miss event',
                  count: 8,
                  icon: 'tabler-urgent',
                  color: 'info',
                },
                {
                  name: 'Adverse',
                  count: 2,
                  icon: 'tabler-alert-triangle',
                  color: 'warning',
                },
                {
                  name: 'Sentinel',
                  count: 4,
                  icon: 'tabler-activity',
                  color: 'error',
                },
              ]"
              :key="type.ticket_type"
              class="my-4"
              cols="12"
              md="6"
              sm="6"
            >
              <VList
                class="card-list"
                style="background: transparent"
              >
                <VListItem>
                  <VListItemTitle class="font-weight-medium">
                    {{ type.name }}
                  </VListItemTitle>
                  <VListItemSubtitle class="text-disabled">
                    {{ type.count }}
                  </VListItemSubtitle>
                  <template #prepend>
                    <VAvatar
                      rounded
                      size="34"
                      :color="type.color"
                      variant="tonal"
                    >
                      <VIcon :icon="type.icon" />
                    </VAvatar>
                  </template>
                </VListItem>
              </VList>
            </VCol>
          </VRow>
        </div>
        <div class="">
          <VueApexCharts
            type="donut"
            height="150"
            width="150"
            :options="timeSpendingChartConfig"
            :series="timeSpendingChartSeries"
          />
        </div>
      </div>
    </VCol>
  </VRow>

  <VRow class="py-2">
    <VCol cols="12">
      <Analytics />
    </VCol>
  </VRow>
</template>
